import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import useVerificationStore from 'stores/verificationStore';
import useFetchConfig from 'services/useFetchConfig';
import useConfigStore from 'stores/configStore';
import { useTranslation } from 'react-i18next';

/**
 * Routes
 */
import MainLayout from 'layouts/MainLayout';
import EntryRoute from 'routes/Entry';
import ExitRoute from 'routes/Exit/Exit';
import TermsRoute from 'routes/Terms';
import PrivacyPolicyRoute from 'routes/PrivacyPolicy';
import NoMatchRoute from 'routes/NoMatch';
import VerifyIdRoute from 'routes/VerifyId/VerifyId';
import VerifyAgeRoute from 'routes/VerifyAge';
import VerifyIdInfoRoute from 'routes/VerifyIdInfo';
import CameraEntryRoute from 'routes/CameraEntry';
import CameraPermission from 'routes/CameraPermission';
import AppealForm from 'routes/AppealForm/AppealForm';
import Aut10tixVerifyId from 'routes/Au10tixVerifyId';
import SelectDocTypeRoute from 'routes/VerifyId/SelectDocType';
import SelectDocCountryRoute from 'routes/VerifyId/SelectDocCountry';

import AdminModal from 'components/AdminModal';
import FullscreenLoader from 'components/FullscreenLoader';
import Text from 'components/Text';

import useUpload from 'hooks/useUpload';
import logger, { useSetupLogger } from 'services/logger';

import AuthenticationProvider from 'services/oidc/AuthenticationProvider';
import ReviewRequest from 'routes/ReviewRequest';
import HumanLayout from 'layouts/HumanLayout';
import PrepareIdVerificationRoute from 'routes/PrepareIdVerification';
import useSetupMixpanel from 'hooks/useSetupMixpanel';
import SignupRoute from 'routes/Signup';
import axios from './services/api/axios';

function MainRoutes() {
  const [loading, setLoading] = useState(true);
  const [tagRequestValid, setTagRequestValid] = useState<boolean | null>(null);
  const setTagRequestId = useVerificationStore((state) => state.setTagRequestId);
  const setCompanySlug = useVerificationStore((state) => state.setCompanySlug);
  const trid = useVerificationStore((state) => state.tagRequestId);
  const sl = useVerificationStore((state) => state.companySlug);
  const { emblemHost } = useFetchConfig();
  const allowSkip = useConfigStore((state) => state.allowSkip);
  const setAgeDetectionConfig = useConfigStore((state) => state.setAgeDetectionConfig);
  const setSkipAgeDetection = useConfigStore((state) => state.setSkipAgeDetection);
  const setSupportWebsiteUrl = useConfigStore((state) => state.setSupportWebsiteUrl);
  const setRedirectUrl = useConfigStore((state) => state.setRedirectUrl);
  const setTag = useVerificationStore((state) => state.setTag);
  const setMobileDeepLink = useVerificationStore((state) => state.setMobileDeepLink);
  const setPublicConfig = useConfigStore((state) => state.setPublicConfig);
  const { t, i18n } = useTranslation();

  const searchParams = new URLSearchParams(window.location.search);
  const tagRequestId = searchParams.get('tagRequestId') || trid;
  const reviewRedirectUrl = searchParams.get('reviewRedirectUrl') || '';
  const mobileDeepLink = searchParams.get('mobileDeepLink');
  const slug = searchParams.get('slug') || sl;

  const location = window.location.pathname;
  const isSignupRoute = location === '/signup';

  useSetupMixpanel();
  useSetupLogger(process.env.REACT_APP_HOST || window.location.origin);

  const uploadFile = useUpload();

  useEffect(() => {
    if (allowSkip) {
      setLoading(false);
    }
  }, [allowSkip]);

  useEffect(() => {
    logger.setMessageAggregate('language', i18n.language.split('-')[0]);
    logger.setMessageAggregate('locale', window.navigator.language || '');
  }, [i18n.language]);

  useEffect(() => {
    if (mobileDeepLink) {
      setMobileDeepLink(decodeURIComponent(mobileDeepLink));
    }
  }, [mobileDeepLink, setMobileDeepLink]);

  useEffect(() => {
    if (slug) {
      setCompanySlug(slug);
    }
  }, [slug, setCompanySlug]);

  useEffect(() => {
    if (tagRequestId && tagRequestId !== trid) {
      setTagRequestId(tagRequestId);
    }
  }, [trid, tagRequestId, setTagRequestId]);

  useEffect(() => {
    if (!tagRequestId) {
      setLoading(false);
      return;
    }
    if (!emblemHost || tagRequestValid) {
      return;
    }

    const checkTagRequest = async () => {
      try {
        const res = await axios.get(`/api/safe-passage/v1/emblem-integration/trust-requestor/tag-requests/${tagRequestId}`);
        if (res.status === 200) {
          const { tag, networkId: nId, authoritySlug: authorityCompanySlug } = res.data;

          if (!tag) {
            setLoading(false);
            setTagRequestValid(false);
            return;
          }

          if (nId) {
            setPublicConfig({ networkId: nId });
          }

          if (authorityCompanySlug) {
            setCompanySlug(authorityCompanySlug);
          }

          setTagRequestValid(true);

          setTag(tag);
          logger.setMessageAggregate('tag', tag);

          // Now that we are using Jordan's model the age is alway 27 unless the tag is verityguard_18+_l2
          // This tag will always require ID upload
          if (tag === 'verityguard_18+_l2' || tag === '18+_idai') {
            setAgeDetectionConfig({ threshold: 999 });
          }

          setRedirectUrl(reviewRedirectUrl || res.data.redirectUrl || null);

          if (res.data.supportWebsiteUrl) {
            setSupportWebsiteUrl(res.data.supportWebsiteUrl);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    checkTagRequest();
  }, [tagRequestId, emblemHost, tagRequestValid, reviewRedirectUrl,
    setAgeDetectionConfig, setSkipAgeDetection, setRedirectUrl, setSupportWebsiteUrl, setTag, setPublicConfig, setCompanySlug]);

  if (isSignupRoute) {
    return (
      <Routes>
        <Route path="signup" element={<SignupRoute />} />
      </Routes>
    );
  }

  return (
    <>
      {loading && <FullscreenLoader />}
      {!loading && (tagRequestValid || allowSkip) && (
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="*" element={<NoMatchRoute />} />
            <Route path="exit" element={<ExitRoute />} />
            <Route path="terms" element={<TermsRoute />} />
            <Route path="privacy-policy" element={<PrivacyPolicyRoute />} />
            <Route path="appeal" element={<AppealForm uploadFile={uploadFile} />} />
          </Route>

          <Route element={<HumanLayout />}>
            <Route element={<MainLayout includeBackButton />}>
              <Route path="camera-permission" element={<CameraPermission />} />
              <Route path="verify-age" element={<VerifyAgeRoute />} />
              <Route path="prepare-id-verification" element={<PrepareIdVerificationRoute />} />
            </Route>
            <Route index element={<CameraEntryRoute />} />
            <Route path="select-doc-country" element={<SelectDocCountryRoute />} />
            <Route path="verify-id" element={<VerifyIdRoute />} />
            <Route path="verify-id-info" element={<VerifyIdInfoRoute />} />
            <Route path="select-doc-type" element={<SelectDocTypeRoute />} />
          </Route>

          {/* Au10tix routes */}
          <Route path="au10tix" element={<MainLayout basePath="au10tix" />}>
            <Route index element={<EntryRoute />} />
            <Route path="verify-id" element={<Aut10tixVerifyId />} />
            <Route path="exit" element={<ExitRoute authority="au10tix" />} />
            <Route path="appeal" element={<AppealForm uploadFile={uploadFile} />} />
            <Route path="*" element={<NoMatchRoute />} />
          </Route>
        </Routes>
      )}
      {!loading && !tagRequestValid && !allowSkip && (
        <div className="flex justify-center items-center h-full">
          <Text>{t('invalidRequest')}</Text>
        </div>
      )}
    </>
  );
}

function AppRoutes() {
  const admin = process.env.NODE_ENV === 'development' || window.location.host.includes('staging');
  return (
    <>
      {admin && <AdminModal />}
      <BrowserRouter>
        <Routes>
          <Route
            path="review-request"
            element={(
              <AuthenticationProvider>
                <ReviewRequest />
              </AuthenticationProvider>
            )}
          />
          <Route path="*" element={<MainRoutes />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default AppRoutes;
